//import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';

import io from 'socket.io-client';
import Messages from './componentes/Messages';
import MessageInput from './componentes/MessageInput';



function App() {
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ['polling']
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  const [mensajesdic, setMensajesdic] = useState({});
  const [openchat, setOpenchat] = useState(false);
  const [enableSuggestions, setEnableSuggestions] = useState(false);
  const [enableText, setEnableText] = useState('Activar Sugerencias')



  const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const appendMessage = (msg, type) => {
    setMensajesdic((prevMessages) => {
      const newMessages = { ...prevMessages };
      // Crea en el dicionario un mensaje con fecha
      const dateSent = Date.now()
      newMessages[dateSent] = [msg, `message ${type}`]
      sleep(20);
      return newMessages
    })
  }

  const onCloseOpen = () => {
    setOpenchat(!(openchat))
    setMensajesdic({})
    setEnableSuggestions(false)
    setEnableText('Activar sugerencias')
    socket.emit('user_uttered', {
      "message": "Hola AVI :)",
    });
    appendMessage("Hola AVI :)", "sent");

  }

  const onClick = () => {
    if (enableSuggestions) {
      appendMessage("¡Las sugerencias han sido desactivadas!", "estado");
      setEnableText('Activar sugerencias')

    } else {
      appendMessage("¡Las sugerencias han sido activadas!", "estado");
      setEnableText('Descativar sugerencias')
    }
    setEnableSuggestions(!(enableSuggestions));
  }



  return (
    <div className="App">
      {(socket && openchat) ? (
        <div className="main-content">

          <div className='span-chat'> 
          <div className='row'>
            <img className='imagen-logo' width="43px" height="43px" src={require('./imagenes/robot.png')} alt="Vacunas-bot" />
            <div className='titulo-area'>
              <div className='texto-titulo'> Asistente de vacunas AVI</div>
              <div className='texto-status'> Online</div>
            </div>
            <button className='close-chat' onClick={onCloseOpen}> <img  width="20px" height="20px" src={require('./imagenes/close.png')} alt="cerrar"/> </button>
          </div>
          <div className='content-button-suggest'>
            <button className='enable-disable' onClick={onClick}> {enableText} </button>
          </div>
          </div>
          <div className="chat-container">
            <Messages socket={socket} mensajesdic={mensajesdic} setMensajesdic={setMensajesdic} />
          </div>
          <div>
            <MessageInput socket={socket} mensajesdic={mensajesdic} setMensajesdic={setMensajesdic} enableSuggestions={enableSuggestions} />
          </div>
        </div>
      ) : (
        < >
          <button className='conect-button' onClick={onCloseOpen} > <img className='imagen-logo' height="54px" width="54px" src={require('./imagenes/robot.png')} /> Conectarse con AVI</button>
        </>
      )}
    </div>
  );
}
export default App;


/*
import React from 'react'
import './App.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function App() {
  const movieItems = [
    {
      id: 0,
      title: "Titanic",
      description: "A movie about love",
    },
    {
      id: 1,
      title: "Dead Poets Society",
      description: "A movie about poetry and the meaning of life",
    },
    {
      id: 2,
      title: "Terminator 2",
      description: "A robot from the future is sent back in time",
    },
    {
      id: 3,
      title: "Alien 2",
      description: "Ripley is back for a new adventure",
    },
  ];
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
        <button className='button-autocomplete'>{item.title}</button>
    )
  }

  

  const handleOnClear = () => {
    console.log("Cleared");
  };

  return (
    <div className="App">
     
        <div className='autocomplete-container'>
          <ReactSearchAutocomplete
            items={movieItems}
            fuseOptions={{ keys: ["title", "description"] }} // Search on both fields
            resultStringKeyName="title" // String to display in the results
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            formatResult={formatResult}
            showIcon={false}
            styling={{
              height: "34px",
              border: "3px hidden black",
              borderRadius: "15px",
              backgroundColor: "white",
              hoverBackgroundColor: "null",
              color: "black",
              fontSize: "16px",
              fontFamily: "sans-serif", //sans-serif",
              iconColor: "black",
              lineColor: "null",
              placeholderColor: "lightgreen",
              clearIconMargin: "8px 8px 5px 5px",
              zIndex: 1,
            }}
          />
        </div>
    </div>
  )
}

export default App */
