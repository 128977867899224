import React, { useEffect, useState, useRef, Fragment } from 'react';
import '../hojas-de-estilo/Messages.css';
import BotonRespuesta from './BotonRespuesta';
import { Dot } from 'react-animated-dots';

const Messages = ({ socket, mensajesdic, setMensajesdic }) => {

  const [date, setDate] = useState("");

  const appendMessage = (msg, type) => {

    setMensajesdic((prevMessages) => {
      const newMessages = { ...prevMessages };
      newMessages[Date.now()] = [msg, `message ${type}`]
      sleep(20);
      return newMessages
    })
  }

  const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }


  useEffect(() => {
    const appendMessage = (msg, type) => {

      setMensajesdic((prevMessages) => {
        const newMessages = { ...prevMessages };
        newMessages[Date.now()] = [msg, `message ${type}`]
        sleep(20);
        return newMessages
      })


    }

    const sleep = (milliseconds) => {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    }


    const appendQuickReplies = (quickReplies, type) => {

      const arrayLength = quickReplies.length;
      const voidArray = {}

      for (var i = 0; i < arrayLength; i++) {
        voidArray[i] = [quickReplies[i].title, quickReplies[i].payload]
      }
      setMensajesdic((prevMessages) => {
        const newMessages = { ...prevMessages };
        sleep(20);
        newMessages[Date.now()] = [voidArray, type]
        return newMessages
      })
    }

    const messageListener = (response) => {
      if (response.text) {
        appendMessage(response.text, "received");
        //console.log(response)
      }
      sleep(20);
      if (response.quick_replies) {
        appendQuickReplies(response.quick_replies, "quickreplies");
        //console.log(response)
      }

    }

    socket.on('bot_uttered', messageListener);
    return () => {
      socket.off()
    };
  }, []);

  /* {[...Object.values(messages)]
        .sort((a, b) => a.time - b.time)
        .map((message) => (
          <div
            key={message.id}
            className="message-container"
            title={`Sent at ${new Date(message.time).toLocaleTimeString()}`}
          >
            <span className="user">{message.user.name}:</span>
            <span className="message">{message.value}</span>
            <span className="date">{new Date(message.time).toLocaleTimeString()}</span>
          </div>
        ))
      }
      */
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  // {console.log(element[0].split('link{').pop().split('}')[0])}

  // fit build 
  const handleMessages = (element, i) => {

    if (element[1] === 'message received') {
      if (element[0].match(/\$.+?\$/g)) {
        return (
          <div className='custom-div'>
            <div > <img height="40px" width="40px" src={require('../imagenes/robot.png')} />  </div>
            <div key={i} className={element[1]}>   {element[0].split("$")[0]}
              <p>
                <a className="links" href={element[0].match(/\$.+?\$/g)[0].slice(1, -1)} target="_blank"> Más información aqui </a>
              </p>
            </div>
          </div>)

      } else {
        return (
          <div className='custom-div'>  <div > <img height="40px" width="40px" src={require('../imagenes/robot.png')} />  </div>
            <div key={i} className={element[1]}>   {element[0].split("$")[0]}   </div>
          </div>)
      }
      //console.log(element[0].match(/\$.+?\$/g) == undefined )

    } else if (element[1] === 'message sent') {
      return (
        <div key={i} className={element[1]}>
          {element[0]}
        </div>)
    } else if (element[1] === 'quickreplies') {
      return (
        <div key={i} className="button-reponse-div"> {[...Object.values(element[0])].map((e, i) =>
          <button className='button-reponse' key={i} onClick={() => { socket.emit('user_uttered', { "message": e[1] }); appendMessage(e[0], "sent"); }}>
            {e[0]}  </button>)}
        </div>)
    } else if (element[1] == 'message estado') {
      return <div key={i} className={element[1]}>  {element[0]}   </div>
    }
  }


  useEffect(() => {
    scrollToBottom()
  }, [mensajesdic]);

  // es un diccionario ordenado por fecha donde en su interior contiene un array que [contenido, typo de mensaje]
  return (

    <div className="messages">
      {[...Object.values(mensajesdic)].map((e, i) => handleMessages(e, i))}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default Messages;