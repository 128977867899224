import React, { useState } from 'react';
import '../hojas-de-estilo/MessageInput.css';
import { Hint } from 'react-autocomplete-hint'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Autocomplete from "./Autocomplete";

var stringSimilarity = require("string-similarity");


const NewMessage = ({ socket, mensajesdic, setMensajesdic, enableSuggestions }) => {
  const [value, setValue] = useState('');

  const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const appendMessage = (msg, type) => {
    setMensajesdic((prevMessages) => {
      const newMessages = { ...prevMessages };
      // Crea en el dicionario un mensaje con fecha
      const dateSent = Date.now()
      newMessages[dateSent] = [msg, `message ${type}`]
      sleep(300);
      return newMessages
    })
  }

  const submitForm = (e) => {
    e.preventDefault();

    socket.emit('user_uttered', {
      "message": value,
    });
    appendMessage(value, "sent");
    setValue('');
  };


  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [rankedSuggestions, setRankedSuggestions] = useState({});
  

  const onChange = (e) => {
    const userInput = e.target.value;
    const unLinked = suggestions.filter(
      (suggestion) => {
        const str1 = userInput.replace(/[^a-zA-Z ]/g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        const str2 = suggestion.replace(/[^a-zA-Z ]/g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

        const simil = stringSimilarity.compareTwoStrings(str1, str2);

        setRankedSuggestions((prevSentences) => {
          const newSentences = { ...prevSentences }
          newSentences[suggestion] = 1 - simil
          return newSentences
        })
        if (simil < 0.30) {
          return false
        } else if (simil >= 0.30) {
          return true
        }
      }
    );
    const keysSorted = Object.keys(rankedSuggestions).sort(function (a, b) { return rankedSuggestions[a] - rankedSuggestions[b] })
    setValue(e.currentTarget.value);
    setFilteredSuggestions(keysSorted);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setRankedSuggestions({});
    setValue(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);

    // en el caso que el usuario haga click en las sugerencias
    // estas arrancan altiro jej

    // en el caso que el usuario haga click en las sugerencias
    // estas arrancan altiro jej

    socket.emit('user_uttered', {
      "message": e.target.innerText,
    });
    appendMessage(e.target.innerText, "sent");
    setValue('');
  };



  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      //setValue(filteredSuggestions[activeSuggestionIndex]);
      setValue(e.target.value);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      setRankedSuggestions({});
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };



  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <div className="suggestions">
        <p className='suggest-title'>Sugerencias</p>
        {
          filteredSuggestions.map((suggestion, index) => {
            let className;
            // Flag the active suggestion with a class
            if (index === activeSuggestionIndex) {
              className = "suggestion-active";
            }
            if (index < 3) {

              return (
                <button className="button-suggest" key={suggestion} onClick={onClick}>
                  {suggestion}
                </button>
              );
            }
          })
        }
      </div>
    ) : (
      <div class="no-suggestions">

      </div>
    );
  };

  const suggestions = [
    "¿Qué es la vacunación?",
    "¿Cómo actúan las vacunas?",
    "¿Por qué se deben administrar las vacunas",
    "¿Qué reacciones puede provocar la administración de la vacuna?",
    "¿A qué edad se administra la vacuna?",
    "¿Qué es la tuberculosis?",
    "¿Qué es el PAP?",
    "¿Qué es la rubéola?",
    "¿Qué es la Parotiditis o Paperas?",
    "¿Qué es Sarampión?",
  ]

  return (
    <>
      <form className="chat-input-form" onSubmit={submitForm}>
        {showSuggestions && value && <SuggestionsListComponent /> && enableSuggestions ? (showSuggestions && value && <SuggestionsListComponent />) : (<div className="no-suggestions" >  </div>)}
        <input
          className='input-form'
          autoFocus
          value={value}
          placeholder="Escribe tu Pregunta a AVI"
          onChange={onChange}
          onKeyDown={onKeyDown}
        //onKeyDown={onKeyDown}
        />
        <button className='button-form'> <img className='' height="24px" width="24px" src={require('../imagenes/send.png')} alt="enviar" /> </button>
      </form>
    </>

    /*
    <form className="chat-input-form" onSubmit={submitForm}>
      <input
      className='input-form'
        autoFocus
        //onChange={onChange}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
        onKeyDown={onKeyDown}
        placeholder="Escribe tu Pregunta a AVI"
        value={value}
      />
      {showSuggestions && value && <SuggestionsListComponent />}
    </form>
    */
  );
};

export default NewMessage;

/*  
    <form classname="chat-input-form" onSubmit={submitForm}>
      <input
        className='input-form'
        autoFocus
        value={value}
        placeholder="Escribe tu Pregunta a AVI"
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      />
      <button className='button-form'> Enviar </button>
    </form>
    */